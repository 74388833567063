import React from 'react'
import background from "../assets/aboutBG.jpg";
import success from '../assets/success.jpg'
import leader from '../assets/leader.jpg'
import {FontIcon, mergeStyles} from '@fluentui/react'

const iconClass = mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
    margin: '0 20px 40px 10px',
    color: '#2466b7'
  });

const Abouts = [
    {
        key:'serviceLove',
        title: 'Service With Love',
        text: 'We are doing our best to show love and appreciation through our work. It involves care, closeness, protectiveness, and trust.',
        icon: 'Emoji2'
    },
    {
        key:'bringTop',
        title: 'Bring You To The Top',
        text: 'We are motivated in helping you achieve your goals or reach your full potential. We are helping you become successful and get to the top.',
        icon: 'Movers'
    },
    {
        key:'support',
        title: 'Great Support',
        text: 'We are there for you when you need, listening to their concerns, and helping you find solutions to their problems. It means being patient, empathetic, and understanding of your situation',
        icon: 'Telemarketer'
    },
    {
        key:'fast',
        title: 'Go Very Fast',
        text: 'We are able to respond quickly to customer requests and provide them with the help they need in a timely manner. We have efficient processes in place that allow us to handle customer inquiries and issues quickly and effectively.',
        icon: 'UserEvent'
    },
    {
        key:'matchBiz',
        title: 'Business Matching',
        text: 'By connecting businesses that share similar goals, values, or target markets, we can help them find new customers, expand their reach, and develop new products or services that meet the needs of their customers.',
        icon: 'Commitments'
    },
    {
        key:'growBiz',
        title: 'Growing Your Business',
        text: 'From providing financial support to offering advice and guidance on strategy and operations, we help your business grow and focus on building strong relationships with your customers and other stakeholders.',
        icon: 'AddGroup'
    },
]

function About() {
  return (
    <div style={{width: '100%' }}>
        <div className='topBG' style={{ backgroundImage: `url(${background})`, height: '180px', width: '100%', backgroundSize : 'cover' }}>
            <div className='aboutBox'>
               <div className="header">
                About Us
               </div> 
               <div className="subtext">
               WE PROVIDE THE SOLUTION FOR ASSET MANAGEMENT.
               </div>
            </div>
        </div>
        <div className="aboutCards">
            {Abouts.map(item => {
                return (
                <div key={item.key} className="aboutCard">
                    <FontIcon aria-label="Compass" iconName={item.icon} className={iconClass} />
                    <h4 style={{marginBottom: '15px'}}>{item.title}</h4>
                    <p>{item.text}</p>
                </div>
            )})}
        </div>


        <div className="success">
            <div className='successBoxBg' style={{ backgroundImage: `url(${success})`, backgroundSize : 'cover' }}>
                <div className='successBox'>
                    <div className="header">
                        The Story Behind Our Success
                    </div> 
                </div>
            </div>
            <div className="successStory">
            “In order to accumulate, grow, and preserve wealth in today's complex world, it is essential to understand and take advantage of the many investment planning options available. Bloomwealth Brand Equity Broker Pvt Ltd team is committed to providing solutions that meet your needs. We take a comprehensive approach to each situation and develop customized strategies on behalf of our clients. 
                    <br/><br />
            Our experienced staff will work closely with you to understand your specific needs and financial objectives. We are passionate about building long-term relationships and delivering high-quality, responsive service. We offer a full range of wealth management services specifically tailored to meet the needs of individuals, businesses, and non-profit organizations. We invite you to meet with us about the broad range of services we offer and have provided to generations of Indian residents.”
            </div>
        </div>

        <div className="leader">
            
            <div className="successStory">
            Mr. Kanjilal is business graduate with 15 years of experience in financial sector. He started his career working with various top brokerage firms of India like Motilal Oswal, SMC Global, Religare, Nirmal Bang. He also served as Regional Head of Intime Equities Ltd. for 5 years. 
            <br /><br />
            At the end of 2016 He started his own wealth management firm in name of “Brand Equity”. After successful running of firm under his leadership for more than 3 years recently it got renamed as “Bloomwealth Brand Equity Broker Pvt. Ltd.”. He is also an avid reader, keen football lover and enthusiastic graphic designer.
            </div>

            <div className='successBoxBg' style={{ backgroundImage: `url(${leader})`, backgroundSize : 'cover' }}>
                <div className='successBox'>
                    <div className="header">
                        The Story of  Our CEO
                    </div> 
                </div>
            </div>
        </div>
    </div>
  )
}

export default About