import React, { useEffect, useRef, useState } from 'react'
// import background from "../assets/wideBG.png";
import crown from "../assets/king.jpg";
import background3 from "../assets/bg.png";
import step from "../assets/step.jpg";
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const carousel = [
    {
        largeText: 'Welcome to Bloomwealth Brand Equity!',
        smallText: 'The name that resonates reliability, stability & confidence! Partners have joined in the experience.',
        img: background3,
        navigateTo: '/about',
        buttonText: 'LEARN MORE',
        color: '#cb1210',
    },
    {
        largeText: 'Join the BEST!',
        smallText: 'Reap the benefits of saving, investing, and budgeting, get a personalized plan that suits you needs and goals.',
        img: crown,
        navigateTo: '/product',
        buttonText: 'OUR OFFERINGS',
        color: '#cb1210',
    },
    {
        largeText: 'Take the next step with us!',
        smallText: 'We invite you to join us and benefit from our professional services and our friendly team. You won\'t regret choosing us.',
        img: step,
        navigateTo: '/contact',
        buttonText: 'CONTACT US',
        color: '#cb1210',
    }
]

function Home() {

    const navigate = useNavigate();
    let interValTimer = useRef<string | number | NodeJS.Timer | undefined>();
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
    const [carouselScreenNo, setCarouselScreenNo] = useState<number>(0);

    useEffect(() => {
        interValTimer.current = setInterval(()=> {
            setCarouselScreenNo(current => {
                let nextScr = (current + 1) % carousel.length;
                return nextScr;     
            });
        }, 5000)

        return () => { 
            if(interValTimer) clearInterval(interValTimer.current)
        };
    },[setCarouselScreenNo])

    const nextScreen = () => {
        let nextScr = (carouselScreenNo + 1) % carousel.length;
        setCarouselScreenNo(nextScr);
        if(interValTimer) clearInterval(interValTimer.current);
    }

    const prevScreen = () => {
        let nextScr = (carouselScreenNo - 1 + carousel.length) % carousel.length;
        setCarouselScreenNo(nextScr);
        if(interValTimer) clearInterval(interValTimer.current)
    }

  return (
    <div style={{width: '100%' }}>
        <div className='carouselImages' style={{ backgroundImage: `url(${carousel[carouselScreenNo].img})`, height: '500px', width: '100%', backgroundSize : 'cover' }}>
            
            <div className='welcomeBox'>
               <div className="header" style={{color: `${carousel[carouselScreenNo].color}`}}>
                {carousel[carouselScreenNo].largeText}
               </div> 
               <div className="subtext">
               {carousel[carouselScreenNo].smallText}
               </div>
               <Button onClick={() =>{scrollToTop(); navigate(carousel[carouselScreenNo].navigateTo);}} style={{ fontWeight: 'bold',padding: '10px 15px', fontSize: '18px'}} variant="bered">{carousel[carouselScreenNo].buttonText}</Button>{' '}
            </div>

            <div className="carouselControls">
                <div onClick={nextScreen} className="arrow arrowRight">▹</div>
                <div onClick={prevScreen} className="arrow arrowLeft">◃</div>              
            </div>

            <div className="rounds">
                {carousel.map((item, index ) => {
                    return index === carouselScreenNo ?
                            <div key={index} className="round activeRound"></div> :
                            <div key={index} className="round"></div> 
                    
                    }
                )}
            </div>

            {/* <div className="stopRotation">
                <div className="round">♺</div>
            </div> */}


        </div>
        <div className="homeDetails">
            <div className="moto">
                <div className="motoheader">
                    <span className='borderBtm'> WHAT W</span>E <b>BELEIVE</b>
                </div>
                <br/>
                <div className="text">
                    The Secret Of Success In Life Is For
                    Every Person To Be Ready For Their
                    Opportunity When It Comes.
                    <br/>
                    
                    It's Your Time Now Grab The
                    Opportunity
                    <br/>
                </div> 
                <br/>
                Be
                <br/>
                <b><span className='red'>SAFE</span> From Fake Commitments</b>
                <br/>
                <b><span className='red'>SAVE</span> Your Earnings</b>
                <br/>
                <b><span className='red'>SECURE</span> Your Future With Us.</b>
            </div>

            <div className="whatwedo">
                <div className="whatwedoheader">
                    <span className='borderBtm'> WHAT W</span>E <b>DO</b>
                </div>
                
                <div className="content">
                <br/>
                The central focus of our business is growing
                wealth, preserving it and distributing same to
                beneficiaries. 
                <br/>
                Wealth Management is a
                comprehensive financial plan/solution that is
                targeted towards High Net-worth individuals
                and their families for the purpose of helping
                them achieve their life goals of wealth
                preservation, growth and enhancement, as
                well as a smooth transfer of this wealth. <br/>
                It
                involves financial planning, investment
                management, succession planning, retirement
                planning, tax planning and other services. 
                </div>
           
            </div>

        </div>
       
    </div>
  )
}

export default Home