import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Route, Routes} from "react-router-dom"
import Navigationbar from './Components/Navbar';
import './custom.scss';
import Home from './Components/Home';
import Footer from './Components/Footer';
import About from './Components/About';
import Product from './Components/Product';
import Career from './Components/Career';
import Contact from './Components/Contact';

function App() {
  return (
    <>
    <Navigationbar/>
    <div className="routerContent">
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/home' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/product' element={<Product/>}/>
        <Route path='/career' element={<Career/>}/>
        <Route path='/contact' element={<Contact/>}/>
    </Routes>
    </div>
    
    <Footer/>
    </>  
  );
}

export default App;
