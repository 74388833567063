import React from 'react'
import background from "../assets/aboutBG.jpg";
import meeting from '../assets/meeting.jpg';
import { Button } from 'react-bootstrap';

function Contact() {

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    }

  return (
    <div style={{width: '100%' }}>
    <div className='topBG' style={{ backgroundImage: `url(${background})`, height: '180px', width: '100%', backgroundSize : 'cover' }}>
        <div className='aboutBox'>
        <div className="header">
        Contact Us
        </div> 
        <div className="subtext">
        WE'RE WAITING FOR YOUR RESPONSES
        </div>
        </div>
    </div>

    <div style={{marginTop: '20px'}} className="contact">
        
        <div style={{paddingTop: '5%', fontSize: '22px', lineHeight: '35px'}} className="careerStory">
        <p> We can only provide so much details on our website. But we're sure you're eager to learn more. And, we are always ready to entertain all your
        queries and provide all the support you need. You're always just a call or email away from your financial well-being. </p><br />
        <Button onClick={scrollToBottom} style={{ fontWeight: 'bold',padding: '10px 15px', fontSize: '18px'}} variant="primary">CONTACT US ⇩</Button>{' '}
        </div>

        <div className='careerBoxBg' style={{ backgroundImage: `url(${meeting})`, backgroundSize : 'cover' }}>
            <div className='careerBox'>
            </div>
        </div>
    </div></div>
  )
}

export default Contact