import React, {useState} from 'react'
import { Pivot, PivotItem, TextField, Persona, PersonaSize, FontIcon, mergeStyles, PrimaryButton, Dialog, DialogFooter,DialogType, DefaultButton, MessageBar, MessageBarType } from '@fluentui/react';
import CEO from '../assets/ceo.jpg';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useBoolean } from '@fluentui/react-hooks';

  const iconClass = mergeStyles({
    fontSize: 25,
    height: 25,
    width: 25,
    margin: '0 20px 0 10px',
    color: '#2466b7'
  });

  const dialogStyles = { main: { maxWidth: 450 } };

    const dialogContentProps = {
    type: DialogType.normal,
    title: 'Redirect To Mailing App',
    closeButtonAriaLabel: 'Close',
    subText: 'This will redirect to your e-mailing app. Do you want to continue?',
    };

  const OrgItems =[
        {
            key: 'address',
            icon: 'CityNext',
            text: '12 Goverment Place East,(Also Known Hemanta Basu Sarani), 1st Floor Mezzanine Room, No.1M-B,(Beside Great Eastern Hotel) Kolkata – 700069'
        },
        {
            key: 'phone',
            icon: 'Phone',
            text: '1800-212-242-424'
        },
        {
            key: 'mail',
            icon: 'MailForward',
            text: 'info@bloomwealthbrandequity.com'
        }
  ]

function Footer() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [showMessage, setShowMessage] = useState(false);
    let timer: string | number | NodeJS.Timeout | undefined;

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const modalProps = React.useMemo(
        () => ({
          isBlocking: false,
          styles: dialogStyles,
        }),
        [],
      );

    
    const resetForm = () => {
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
      }

    const MailRedirect=()=>{
        let emailTo="info@bloomwealthbrandequity.com";
        console.log(message);
        let body = `${message}\n\nFrom,\n${name}\n(${email})`;
        window.open(
        `mailto:${emailTo}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`);
        resetForm();
      }

      const checkAndMail = () => {
        clearTimeout(timer);
        if( name.trim() === '' || email.trim() === '' || subject.trim() === '' || message.trim() === '' ){
            setShowMessage(true);
            timer = setTimeout(() => {
                setShowMessage(false)
            },5000);
        }
        else{
            toggleHideDialog();
        }
      }


  return (
    <div className="footer">
    <div className="details">
        <Pivot aria-label="Basic Pivot Example">
            
            <PivotItem headerText="Organization">
                <div className='pivotItem'>
                    <Persona
                        imageUrl = {CEO}
                        imageInitials={'SK'}
                        text = {'Sujoy Kanjilal'}
                        secondaryText = {'CEO of Bloomwealth Brand Equity'}
                        size={PersonaSize.size100}
                        imageAlt="Sujoy Kanjilal"
                    />
                    <h4 style={{marginTop: '30px', marginBottom: '20px'}}>Head Office</h4>
                    {OrgItems.map(item => {
                        return (
                            <div key={item.key} style={{display: 'flex', marginBottom: '10px'}}>
                                <FontIcon aria-label="Compass" iconName={item.icon} className={iconClass} />
                                <div style={{fontSize: '18px', lineHeight: '35px', fontWeight: 'normal'}}>{item.text}</div>
                            </div>
                        )
                    })}
                    
                </div>
            </PivotItem>
            <PivotItem headerText="Services">
            <div className='pivotItem'>
            <p className='serviceHeader'>We work with different products and provide a large number of business offerings.</p>
                    <div className='services'>
                        <div className="product">
                            <p className='listHeader'><b>Our Products</b></p>
                            <ul>

                            <li>&nbsp;Equity, Commodity & Currency</li>
                            <li>&nbsp;Trading with Demat Services</li>
                            <li>&nbsp;Wealth Management & Creation</li>
                            <li>&nbsp;Life & General Insurance</li>
                            <li>&nbsp;Mutual Funds</li>
                            <li>&nbsp;IPOs</li>
                            <li>&nbsp;Bonds & Debentures</li>
                            <li>&nbsp;Govt FDs & Bank Loans</li>
                            </ul>
                        </div>
                        <div className="service">
                            <p className='listHeader'><b>Our Business Offers</b></p>
                            <ul>

                            <li>&nbsp;Channel Partners</li>
                            <li>&nbsp;Satellite Branches</li>
                            <li>&nbsp;Business Associates</li>
                            </ul>
                            <br />
                            <Button onClick={() => {navigate('/product'); scrollToTop();}} className='serviceButton' variant="primary">LEARN MORE</Button>{' '}
                        </div>
                    </div>
                </div>
            </PivotItem>
            {/* <PivotItem headerText="Our Cases">
                <Label styles={labelStyles}>Cases</Label>
            </PivotItem>
            <PivotItem headerText="Success Stories">
                <Label styles={labelStyles}>Success Stories</Label>
            </PivotItem> */}
        </Pivot>
    </div>

    <div className="callback">
        <div className="callbackheader">
            <span className='borderBtm'> REQUEST </span>A <b>CALLBACK</b>
        </div>
        <br/>
        Please fill out the form and submit. We will get back to you within 1-2 business days.
        <br/>
        <br/>
        <TextField value={name} onChange={(e, value) => setName(value || '')} label="Full Name" placeholder="Enter your full name." />
        <TextField value={email} onChange={(e, value) => setEmail(value || '')} label="Email" placeholder="Enter your email id." />
        <TextField value={subject} onChange={(e, value) => setSubject(value || '')} label="Subject" placeholder="Subject" />
        <TextField value={message} onChange={(e, value) => setMessage(value || '')} label="Message" multiline placeholder="Enter your message." />
        <br/>
        {/* <PrimaryButton style={{ fontWeight: 'bold',padding: '10px 15px', fontSize: '18px'}} text="SUBMIT" /> */}
        <div style={{display: 'flex'}}>
            <Button onClick={checkAndMail} style={{ fontWeight: 'bold',padding: '10px 15px', fontSize: '18px', marginRight: '30px'}} variant="primary">SUBMIT</Button>{' '}
            {showMessage && <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
                style={{ fontSize: '18px', lineHeight: '30px'}}
                styles={{icon:{ fontSize: '20px', height: '20px', width:'20px' , lineHeight: '30px'}}}
                delayedRender={false}
            >Please Fill all the details to continue
            </MessageBar>}
        </div>
        
    </div>
    <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton style={{ fontWeight: 'bold',padding: '10px 15px', color:'white', backgroundColor: '#246bc1'}} onClick={MailRedirect} text="Continue" />
          <DefaultButton onClick={toggleHideDialog} text="Close" />
        </DialogFooter>
      </Dialog>
</div>
  )
}

export default Footer