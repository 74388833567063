import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo1.png';
import { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';


export const NavData = [
  {
      title: "Home",
      path: "/",
    },
    {
      title: "About",
      path: "/about",
    },
    {
      title: "Product & Services",
      path: "/product",
    },
    {
      title: "Career",
      path: "/career",
    },
    {
      title: "Contact Us",
      path: "/contact",
    },
]

function Navigationbar() {

  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}


useEffect(() => {
  let height = window.screen.height;
  let width = window.screen.width;
  setIsMobile(height/width >= 0.7);
},[])

  return (
    <div>
      <Navbar fixed="top" bg="primary" variant="dark" className="mb-1 navBarStyle">
          <Container style={{width: `${0.99 * window.screen.width}px`}} fluid>
          {isMobile && <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-md`}
              aria-labelledby={`offcanvasNavbarLabel-expand-md`}
              placement="start"
              style={{ width: "20%"}}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-start navButton">
                  <NavDropdown
                    title="☰"
                    id={`offcanvasNavbarDropdown-expand`}
                    style={{fontWeight: 'bold', color: 'white'}}
                  >
                  {NavData.map((item, index) => {
                      return (
                      <NavDropdown.Item key={index} style={{marginRight: '10px'}}>
                         <NavLink
                          
                          to={item.path}
                          className={'unformattedNavLink'}>
                          <Nav.Item>{item.title}</Nav.Item>
                        </NavLink>
                      </NavDropdown.Item>
                     )
                  })}
                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>}
            <Navbar.Brand className='navBrand' style={{ cursor: 'pointer', fontWeight: 'bold'}} onClick={()=>{scrollToTop();navigate('/');}}><img
              src={Logo}
              width={isMobile ? "30" :"45"}
              height={isMobile ? "30" :"45"}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />{'  '}
             &nbsp;Bloomwealth Brand Equity
            </Navbar.Brand>
            {!isMobile && <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-md`}
              aria-labelledby={`offcanvasNavbarLabel-expand-md`}
              placement="end"
              style={{marginRight:'60px'}}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {NavData.map((item, index) => {
                      return (
                      <Nav.Link key={index}
                          style={{marginRight: '10px'}}>
                         <NavLink
                          to={item.path}
                          className={'unformattedNavLink'}>
                          <Nav.Item>{item.title}</Nav.Item>
                        </NavLink>
                      </Nav.Link>
                     )
                  })}
                  {/* <NavLink to="/about">About</NavLink>
                  <NavLink to="/product">Products & Services</NavLink>
                  <NavLink to="/career">Career</NavLink>
                  <NavLink to="/contact">Contact</NavLink> */}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>}
            
          </Container>
        </Navbar>
    </div>
  )
}

export default Navigationbar;