import React from 'react'
import background from "../assets/aboutBG.jpg";
import analyze from '../assets/analyze.jpg'
import consult from '../assets/consult.jpg'
import {FontIcon, mergeStyles} from '@fluentui/react'

const iconClass = mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
    margin: '0 20px 40px 10px',
    color: '#2466b7'
  });

const Abouts = [
    {
        key:'EQUITIES',
        title: 'EQUITIES',
        text: 'Involvement in equity investment is much more than just stock trading. It develops the skills of long-term wealth creation. An equity investment generally refers to acquisition and holding of equity stock by individuals and firms in anticipation of income from Dividend and appreciation in the value of the stock.',
        icon: 'DynamicSMBLogo'
    },
    {
        key:'DERIVATIVES',
        title: 'DERIVATIVES',
        text: 'The spectrum of traditional equity trading in stocks and bonds, derivatives trading has gained massive popularity across the world, and more recently in India. An equity derivative is a class of derivatives whose value is at least partly derived from one or more underlying equity securities. Futures & Options are by far the most common equity derivatives',
        icon: 'DOM'
    },
    {
        key:'COMMODITIES',
        title: 'COMMODITIES',
        text: 'Commodities are traded as future contracts. One of the major advantages of Commodity Trading is that you can earn money without actually physically storing commodities. It is one of the investment segments where an individual with limited capital can make extraordinary profits in a relatively short period.',
        icon: 'OEM'
    },
    {
        key:'CURRENCY FUTURES TRADING',
        title: 'CURRENCY FUTURES TRADING',
        text: 'Currency trading is the largest and the most significant market in the globe. They are similar to traditional stock and commodity futures. Currency derivatives are a very good hedging tool for exporters and importers for daily movement in the FOREX market. It\'s also a very low-risk trading segment for its macro-level movement.',
        icon: 'AllCurrency'
    },
    {
        key:'Structured Products',
        title: 'STRUCTURED PRODUCTS',
        text: 'A structured product is a pre-packaged investment strategy based on derivatives, such as a single security, a basket of securities, options, indices, commodities, debt issuance or foreign currencies. It is used to improve the return on a fixed income or an equity instrument while reducing the risk on the product using a derivative instrument as insurance on the downside.',
        icon: 'BuildQueueNew'
    },
    {
        key:'Depository Services',
        title: 'DEPOSITORY SERVICES',
        text: 'The depository is like a bank where instead of money, shares are kept on behalf of the owner. In a Demat account, the investor holds shares and securities electronically instead of its physical possession. A Demat Account is opened by the investor while registering with a SEBI registered broker to enable electronic settlements of trades.',
        icon: 'EntitlementRedemption'
    },
]

function Product() {
  return (
    <div>
        <div className='topBG' style={{ backgroundImage: `url(${background})`, height: '180px', width: '100%', backgroundSize : 'cover' }}>
            <div className='aboutBox productBox'>
                <div className="header">
                    Products & Services
                </div> 
            </div>
        </div>
        <div className="aboutCards">
            {Abouts.map(item => {
                return (
                <div key={item.key} className="aboutCard">
                    <FontIcon aria-label="Compass" iconName={item.icon} className={iconClass} />
                    <h4 style={{marginBottom: '15px'}}>{item.title}</h4>
                    <p>{item.text}</p>
                </div>
            )})}
        </div>

        <div className="success">
            <div className='successBoxBg' style={{ backgroundImage: `url(${consult})`, backgroundSize : 'cover' }}>
                <div className='successBox'>
                    <div className="header">
                    Consulting
                    </div> 
                </div>
            </div>
            <div className="successStory productStory">
                <p className='header'><span className='borderBtm'>Business & Financ</span>ial Consult</p><br /><br />
                We can help businesses identify areas for improvement, develop strategies for growth, and provide guidance on financial planning and management.<br/> By working with us, businesses can gain access to specialized expertise and resources that can help them achieve their goals and overcome challenges.
            </div>
        </div>

        <div className="leader">
            
            <div className="successStory productStory">
            <p className='header'><span className='borderBtm'>Analyze The Oppor</span>tunities</p>
            <br /><br />
            A valuable service for businesses looking to identify new markets, products, or services that can help them grow and succeed. <br /> By analyzing market trends, consumer behavior, and other factors, we can help businesses identify areas of opportunity that they may have overlooked or not considered.
            </div>

            <div className='successBoxBg' style={{ backgroundImage: `url(${analyze})`, backgroundSize : 'cover' }}>
                <div className='successBox'>
                    <div className="header">
                        Analysis
                    </div> 
                </div>
            </div>
        </div>
</div>
  )
}

export default Product