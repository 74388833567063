import React from 'react'
import background from "../assets/aboutBG.jpg";
import career from '../assets/career.jpg'
import { useNavigate } from 'react-router-dom';

function Career() {

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

  return (
    <div style={{width: '100%' }}>
        <div className='topBG' style={{ backgroundImage: `url(${background})`, height: '180px', width: '100%', backgroundSize : 'cover' }}>
            <div className='aboutBox careerBox'>
            <div className="header">
            Work With The Best
            </div> 
            <div className="subtext">
            AND LEARN A LOT OF NEW THINGS
            </div>
            </div>
        </div>

        <div style={{marginTop: '20px'}} className="career">
            
            <div style={{paddingTop: '10%', fontSize: '22px', lineHeight: '35px'}} className="careerStory">
            Managing a system this complex requires a passion for technology. In offices from Kolkata to Bardhamann and couple of places in between, our talented people work hard every day to find solutions, create value, and push boundaries.
            </div>

            <div className='careerBoxBg' style={{ backgroundImage: `url(${career})`, backgroundSize : 'cover' }}>
                <div className='careerBox'>
                    {/* <div className="header">
                        Career
                    </div>  */}
                </div>
            </div>
        </div>
        
        <div className="careerStatement">
        “Bloomwealth Brand Equity is strong believer of People Power. It recognizes the fact that human resource is the asset in business which appreciates. Over the years it has been our constant endeavour to build a culture of ‘We’ feeling across the organization. A well bonded family who think and act together for every business footing to take their home to the next level.
        <br /><br />
        <b>‘Team drives the business’</b>  is our belief under visionary leadership. We are definitely fortunate at this philosophy to believe and practice. It’s one family where each one of us committed to achieve best of business so that our customers are always delighted with our products and services.
        <br /><br />
        We offer avenues to our employees to emerge as thinker to collaborate, debate and innovate. A free and frank environment allows them to break the barriers and bring changes with power of honesty to support mankind by our products and services.
        <br /><br />
        We strongly believe that a strong team only can enable an organization to gain edge in the business. At Bloomwealth Brand Equity we maintain strong focus on preserving and nurturing our people through various employee engagement initiatives like continuous education, training and developmental programmes. Personal and professional growth of the employees has always been a concern for us. We like our people to grow with the business, as we believe growth of people ensures over all business growth. Therefore, Bloomwealth Brand Equity maintains a budget separately for employee development programmes with human face in addition to internal development measures to augment managerial and technical capabilities.
        <br /><br />
        So, if you have the passion to make it big, the dedication towards your work, the desire to learn something new, we will surely give you the recognition for your contribution and achievements. We shall prove that you have joy with us.
        <br /> <br />
        To apply online, please <b className='textlink' onClick={() =>{scrollToTop();navigate('/contact')}}>Contact Us.</b> ”

        </div>

    </div>
  )
}

export default Career